<template>
  <div class="content-box">
    <LuckyGrid ref="LuckyWheel" width="300px" height="300px" :blocks="blocks" :prizes="prizes" :buttons="buttons" @start="startCallBack" @end="endCallBack" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      blocks: [
        { padding: "10px", background: "#869cfa" },
        { padding: "10px", background: "#e9e8fe" },
      ],
      prizes: [
        {
          x: 0,
          y: 0,
          fonts: [{ text: "谢谢参与", top: "60%" }],
          imgs: [
            {
              src: "https://9tuay9kz5ajucd7vwvq62v748kfq324am.png",
              width: "50%",
              top: "15%",
            },
          ],
          background: "#b3c8fa",
        },
        {
          x: 1,
          y: 0,
          fonts: [{ text: "1元红包", top: "60%" }],
          imgs: [
            {
              src: "https://9tuay9kz5ajucd7vwvq62v748kfq324am.png",
              width: "50%",
              top: "15%",
            },
          ],
          background: "#b3c8fa",
        },
        {
          x: 2,
          y: 0,
          fonts: [{ text: "一包辣条", top: "60%" }],
          imgs: [
            {
              src: "https://9tuay9kz5ajucd7vwvq62v748kfq324am.png",
              width: "50%",
              top: "15%",
            },
          ],
          background: "#b3c8fa",
        },
        {
          x: 2,
          y: 1,
          fonts: [{ text: "5元红包", top: "60%" }],
          imgs: [
            {
              src: "https://9tuay9kz5ajucd7vwvq62v748kfq324am.png",
              width: "50%",
              top: "15%",
            },
          ],
          background: "#b3c8fa",
        },
        {
          x: 2,
          y: 2,
          fonts: [{ text: "可乐可乐", top: "60%" }],
          imgs: [
            {
              src: "https://9tuay9kz5ajucd7vwvq62v748kfq324am.png",
              width: "50%",
              top: "15%",
            },
          ],
          background: "#b3c8fa",
        },
        {
          x: 1,
          y: 2,
          fonts: [{ text: "雪碧雪碧", top: "60%" }],
          imgs: [
            {
              src: "https://9tuay9kz5ajucd7vwvq62v748kfq324am.png",
              width: "50%",
              top: "15%",
            },
          ],
          background: "#b3c8fa",
        },
        {
          x: 0,
          y: 2,
          fonts: [{ text: "卫龙卫龙", top: "60%" }],
          imgs: [
            {
              src: "https://9tuay9kz5ajucd7vwvq62v748kfq324am.png",
              width: "50%",
              top: "15%",
            },
          ],
          background: "#b3c8fa",
        },
        {
          x: 0,
          y: 1,
          fonts: [{ text: "谢谢参与", top: "60%" }],
          imgs: [
            {
              src: "https://9tuay9kz5ajucd7vwvq62v748kfq324am.png",
              width: "50%",
              top: "15%",
            },
          ],
          background: "#b3c8fa",
        },
      ],
      buttons: [
        {
          x: 1,
          y: 1,
          background: "#9c9dd8",
          fonts: [{ text: "抽奖", top: "40%" }],
        },
      ],
    };
  },
  mounted() {
    this.getPrizesList();
  },
  methods: {
    getPrizesList() {},
    // 点击抽奖按钮会触发star回调
    startCallBack() {
      // 调用抽奖组件的play方法开始游戏
      this.$refs.LuckyWheel.play();
      // 模拟调用接口异步抽奖
      setTimeout(() => {
        // 假设后端返回的中奖索引是0
        const index = 0;
        // 调用stop停止旋转并传递中奖索引
        this.$refs.LuckyWheel.stop(index);
      }, 3000);
    },
    endCallBack(prize) {
      alert(`恭喜你获得${prize.title}`);
    },
  },
};
</script>
<style lang="less" scoped>
.content-box {
  // background-color: rgb(246, 145, 111);
}
</style>
